import React from "react"
import ClientLogo from "../components/clientsLogo/clientsLogo";
import Header from "../components/header/header";
import Footer from "../components/footer/footer";
import styled from "styled-components";
import { useStaticQuery, graphql } from "gatsby";



export const ContactWrapper = styled.div`
    max-width: 960px;
    margin:15px;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    background-color: #fff;
    box-shadow: 0px 5px 10px #ccc;
    min-height: 550px;
    padding: 15px;
    h2 {
        margin-top: 20px;
        color: #2e308f;
        font-size: 30px;
        margin-bottom: 20px;
        
    }
    .contact-us-map iframe {height:200px;}
    input >.form-control{
        max-height:20px;
    }
    .btn-warning {
        color: #fff;
        background-color: #f0ad4e;
        border-color: #eea236;
    }
    p {
        margin: 0 0 10px;
    }
    input{
        padding :6px 12px;
    }
    .form-group {
        margin-bottom: 15px;
    }
    form {
        display: block;
        margin-top: 0em;
    }
`;


const IndexPage = () => {
    const data = useStaticQuery(graphql`
    {
      allStrapiFooter {
        nodes {
          address
          mail
          mobile
          contact
        }
      }
    }
  `)

const node = data.allStrapiFooter.nodes[0];
    return (
        <ContactWrapper>
            <Header />
                <div className="contact-us-map" style={{ width: `100%` }}>
                    <iframe width="100%" height="350px" src="https://maps.google.com/maps?width=100%&amp;height=600&amp;hl=en&amp;q=Chennai&amp;ie=UTF8&amp;t=&amp;z=12&amp;iwloc=B&amp;output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"><a href="https://www.mapsdirections.info/en/journey-planner.htm">find directions</a></iframe>
                </div>
            <div className="">
            <div className="container">
                <div className="contact-us-wrap row">
                    <div className="col-sm-5">
                        <h2>Corporate office</h2>
                        <div className="col-md-8 col-sm-4"><p>{node.address}</p></div><br></br>
                        <p>Mobile : {node.mobile}</p>
                        <p>phone : &nbsp;&nbsp;{node.contact}</p>
                        <p>Email   :&nbsp; {node.mail}</p></div>
                    <div className="col-sm-5">
                        <h2>Quick Reach us</h2>
                        <form className="contact_form" method="post">
                            <div class="form-group">
                                <input type="text" class="form-control" placeholder="Enter your Full name" name="name"></input>
                            </div>
                            <div className="form-group">
                                <input type="email" class="form-control" placeholder="Email ID" name="email"></input>
                            </div>
                            <div className="form-group">
                                <input type="text" class="form-control" placeholder="Mobile Number" name="number"></input>
                            </div>
                            <div className="form-group">
                                <input type="text" class="form-control" placeholder="Subject" name="subject"></input>
                            </div>
                            <div className="form-group">
                                <textarea class="form-control" name="message" placeholder="Enter Message"></textarea>
                            </div>
                            <button type="submit" class="btn btn-warning btn-block">Submit</button>
                        </form>
                    </div>
                </div>
            </div>
            </div>
            <ClientLogo />
            <Footer />
        </ContactWrapper>
    )
}

export default IndexPage;
